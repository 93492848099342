export const environment = {
  name: "dev",
  production: "production",
  apiUrl: 'https://devserver.labbytes.vibytes.com',
  UserPoolId: 'us-east-1_i3BiwIXNI',
  ClientId: '70fovs99lnubpmjvd48ieprrut'
};



